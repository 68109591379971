

.bottomMainDiv{
    padding-bottom: 20%;
    padding-top: 15%;
}

.bottomMainImageDiv{
    height:420px; 
    margin-top: -20%;
}

.greenBgDiv{
    height:300px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.downloadLinkIconImage{
    width: 40%;
    height: 9%;
}

.textLogoContainerDiv{
    width:10%;
    margin-left:10%;
}

@media screen and (max-width: 767px) {

    .bottomMainImageDiv{
        height:300px; 
        margin-top: -20%;
        margin-left: -20%;
    }

    .greenBgDiv{
        height:200px;
    }

    .textLogoContainerDiv{
        width:50%;
        margin-left:0%;
        margin-top:-17%;
    }

}

@media screen and (max-width: 768px) {

    .bottomMainDiv{
        padding-bottom: 40%;
        padding-top: 50% !important;
    }

    .downloadLinkIconImage{
        width: 80% !important;;
        height: 9%;
    }

}


@media screen and (max-width: 900px) {

    .bottomMainDiv{
        padding-bottom: 40%;
        padding-top: 30%;
    }

    .downloadLinkIconImage{
        width: 70%;
        height: 9%;
    }

    .textLogoContainerDiv{
        width:10%;
        margin-left:7%;
    }
    
}


.purpleTextDiv {
    color: #f6f6f7;
    font-size: 22px;
    width: '100%';
    font-family: "GothamBold";
    text-shadow: 5px 5px 5px rgb(0 0 0 / 71%);
  }

@media screen and (max-width: 765px) {

    .bottomMainDiv{
        padding-bottom: 40%;
        padding-top: 50% !important;
    }

    .downloadLinkIconImage{
        width: 50% !important;;
        height: 9%;
    }

    .textLogoContainerDiv{
        width:50%;
        margin-left:0%;
        margin-top:-13%;
    }

}

@media screen and (max-width: 600px) {

    .textLogoContainerDiv{
        width:50%;
        margin-left:-3%;
        margin-top:-17%;
    }

    .bottomMainDiv{
        padding-bottom: 25%;
        padding-top: 10% !important;
    }

    .downloadLinkIconImage{
        width: 70% !important;;
        height: 9%;
    }

}





