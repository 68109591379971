.invite-code-container {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* background-color: rgb(223, 223, 223); */
    background-color: rgb(255, 255, 255);
    height: 100vh;
    flex-direction: column;
    /* background-image: url('../../assets/images/bg.png'); */
  }

  .invite-code-container-box{
    /* margin: 10px;*/
    padding: 10px; 
    width: 90%;
    max-width: 500px;
    /* background-color: rgba(255, 255, 255, 0.7); */
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .invite-code-box {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: "GothamBold";
  }

  .invite-code-text{
    max-width: 500px;
    margin-top: 15px;
    margin-bottom: 45px;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: "GothamBookItalic";
    font-style: italic;
  }

  .invite-code-stores{
    width: '100%';
    margin-bottom: 50px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .invite-code-title-text{
    font-family: "GothamBold";
  }
  
  .invite-code-input {
    border: 0px black solid;
    border-radius: 3px;
    font-size: 18px;
    width: 120px;
    font-family: "GothamBook";
    background-color: rgba(255, 255, 255, 0);
    outline: none;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .copy-icon {
    align-items:center;
    justify-content: center;
    align-content: center;
    background-color: transparent;
    border: transparent;
    cursor: 'pointer',
  }
  
  .logo{
    width:180px;
    height:180px;
  }

  .invite-code-inner-box{
    width: 150px;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    border: 1px solid;
    border-radius:4px
  }


  .appleStoreIcon{
    width: 41%;
    margin: 10px;
  }

  .playStoreIcon{
    width: 41%;
    margin: 10px;
  }