body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "GothamBold";
  src: local("Gothambold"),
  url("./assets/fonts/Gotham-Bold.otf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "GothamBlack";
  src: local("Gothamblack"),
  url("./assets/fonts/Gotham-Black.otf") format("truetype");
}

@font-face {
  font-family: "GothamBookItalic";
  src: local("Gothambookitalic"),
  url("./assets/fonts/Gotham-BookItalic.otf") format("truetype");
}

@font-face {
  font-family: "GothamLight";
  src: local("Gothamlight"),
  url("./assets/fonts/Gotham-Light.otf") format("truetype");
}

@font-face {
  font-family: "GothamThin";
  src: local("Gothamthin"),
  url("./assets/fonts/Gotham-Thin.otf") format("truetype");
}

@font-face {
  font-family: "GothamThinItalic";
  src: local("Gothamthinitalic"),
  url("./assets/fonts/Gotham-ThinItalic.otf") format("truetype");
}

@font-face {
  font-family: "GothamUltraItalic";
  src: local("Gothamultraitalic"),
  url("./assets/fonts/Gotham-UltraItalic.otf") format("truetype");
}

@font-face {
  font-family: "GothamXLight";
  src: local("Gothamxlight"),
  url("./assets/fonts/Gotham-XLight.otf") format("truetype");
}

@font-face {
  font-family: "GothamXLightItalic";
  src: local("Gothamxlightitalic"),
  url("./assets/fonts/Gotham-XLightItalic.otf") format("truetype");
}

@font-face {
  font-family: "GothamBook";
  src: local("Gothambook"),
  url("./assets/fonts/Gotham-Book.otf") format("truetype");
}

