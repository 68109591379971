.container{
  background-image: url('./assets/images/Background.jpg');
  background-size: cover;
  max-width: 100%;
  padding: 0;
}


.font-face-gtmblack {
  font-family: "GothamBlack";
}

.font-face-gtmbold {
  font-family: "GothamBold";
}

.font-face-gtmbook {
  font-family: "GothamBook";
}

.font-face-gtmbookitalic {
  font-family: "GothamBookItalic";
}

.font-face-gtmlight {
  font-family: "GothamLight";
}

.font-face-gtmthin {
  font-family: "GothamThin";
}

.font-face-gtmthinitalic {
  font-family: "GothamThinItalic";
}

.font-face-gtmultraItalic {
  font-family: "GothamUltraItalic";
}

.font-face-gtmxlight {
  font-family: "GothamXLight";
}

.font-face-gtmxlightitalic {
  font-family: "GothamXLightItalic";
}

.lang-selector{
  margin: 10px;
    border-radius: 5px;
    padding: 2px;
    background-color: transparent;
    color: white;
    font-family: "GothamBook";
    border-color: rgba(253, 254, 255, 0.705);
    width: 200px;
}

.lang-selector .btn {
  background-color: transparent; /* İstediğiniz renk */
  color: white;
  border: solid 1px; /* İsterseniz sınırları da kaldırabilirsiniz */
  width: 200px;
  text-align: left;
}

.lang-selector .btn:hover,
.lang-selector .btn:focus {
  background-color: #345dce; /* İstediğiniz hover rengi */
  color: white;
}

.lang-selector option {
  background-color: transparent;
  border-radius: 25px;
  border-color: rgba(253, 254, 255, 0.705);
  padding: 33px;
  height: 40px;
  color: black;
}

