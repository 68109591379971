.div1 {
    background-image: url("../assets/images/Background.jpg");
    background-size: cover; 
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
}

h1   {
    color: white;
}

h2   {
    color: white; 
}

p   {
    color: white;    
}