*,
*::after,
*::before {
  margin: 0;
  box-sizing: border-box;
}
/* html {
  font-size: 62.5%;
}
body {
  font-size: 1.6rem;
} */
li {
  list-style: none;
}
a {
  text-decoration: none;
  color: rgb(204, 204, 204);
}

ul {
 padding: 0;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 8vh;
  background: rgba(91, 0, 136, 0);
  padding-top: 100px;
  /* padding-bottom: 500px; */
}
.nav__brand {
  text-transform: uppercase;
}

.nav__menu {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 2rem;
}
.nav__toggler {
  display: none;
}
.nav__toggler div {
  width: 2.5rem;
  height: 0.2rem;
  margin: 0.4rem;
  background: rgb(204, 204, 204);
  transition: 0.3s ease-in;
}

@media screen and (max-width: 768px) {
  .nav__toggler {
    display: block;
    cursor: pointer;
    margin-top: 70px;
    position: absolute;
    top: 0;
    right: 0;
    padding-right: 20px;
  }

  .nav__menu {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    position: fixed;
    /* top: 7vh; */
    right: 0;
    top: 0%;
    gap: 0rem;
    /* height: 93vh; */
    height: 30%;
    /* width: 60vw; */
    width: 100%;
    background: rgb(88, 103, 168, 0.847);
    flex-direction: column;
    transform: translateX(100%);
    transition: 0.5s ease-in;
  }

  .headerWhiteDiv {
    color:#ffffff;
    font-family: "GothamBold";
    margin-bottom: -150px;
    font-size: 120%;
  }
  
  .greenButtonDiv {
    background-color:#006e1f;
    margin-right: -10px;
    width:80%;
    font-size: 100%;
  }
 
  .mainLogoDivHeader {
    width: 60%!important;;
    height: 60%!important;;
  }

  
}

/* Active Class */
.nav__active {
  transform: translateX(0%);
}

/* Toggle Icon Animation */

.toggle .line1 {
  transform: rotate(-45deg) translate(-4px, 5px);
}
.toggle .line2 {
  opacity: 0;
}
.toggle .line3 {
  transform: rotate(45deg) translate(-4px, -5px);
}
 
.mainLogoDivHeader {
    /* cursor: pointer; */
    /* border-radius: 100%; */
    width: 70%;
    height: 70%;
    /* overflow: hidden; */
}

.whiteTextDiv {
  color:white;
  text-decoration:none;
  font-family: "GothamBold";
  font-size: 10%;
}

.greenButtonDiv {
  background-color:#1fff5f;
  padding:10px;
  border-radius:20px;
  cursor:pointer;
  width:120%;
  text-align:center;
  font-family: "GothamBold";
  box-shadow:3px 3px 3px rgba(0, 0, 0, 0.8);
  /* font-size: 15px; */
  font-size: 100%;
}

.headerWhiteDiv {
  color:#ffffff;
  font-family: "GothamBold";

}
